.mediaCardPlaceholder {
    aspect-ratio: 1 / 1.6395;
    position: relative;
    overflow: hidden;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -100%; /* Start outside */
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0));
        animation: moveRight 1s linear infinite;
    }
    &::after {
        background: linear-gradient(to right, rgba(128, 128, 128, 0.3), rgba(128, 128, 128, 0));
        animation: moveRight 2s linear infinite;
    }
}

@keyframes moveRight {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}