.text {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.text.active {
    opacity: 1;
}

.text span {
    display: inline-block;
    /* Allow individual span rotation */
    opacity: 0;
    /* Start hidden */
    position: relative;
    transform: translateZ(12px) rotateX(90deg);
    /* Start rotated */
    transform-origin: 50% 50% 12px;
    transition: opacity 0.32s ease-in, transform 0.32s ease-out;
}

.text span.hide {
    transform: rotateX(-90deg);
}

.text span.show {
    opacity: 1;
    /* Fully visible */
    transform: rotateX(0deg);
}

.text span.inactive {
    opacity: 0;
    /* Adjust for inactive character visibility */
}