.saveBtn {
    background-color: rgba($color: #003465, $alpha: 1.0);
    color: rgba($color: #FFFFFF, $alpha: 1.0);
    transition-property: background-color color;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    position: relative;
    overflow: hidden;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -100%; /* Start outside */
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(128, 128, 128, 0), rgba(128, 128, 128, 0));
        animation: moveRight 1s linear infinite;
    }
    &::after {
        background: linear-gradient(to right, rgba(128, 128, 128, 0), rgba(128, 128, 128, 0));
        animation: moveRight 2s linear infinite;
    }
    &.fetching {
        background-color: rgba($color: #003465, $alpha: 0);
        color: rgba($color: #FFFFFF, $alpha: 0);
        &::before {
            background: linear-gradient(to right, rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0));
        }
        &::after {
            background: linear-gradient(to right, rgba(128, 128, 128, 0.3), rgba(128, 128, 128, 0));
        }
    }
}

.avatar {
    position: relative;
    overflow: hidden;
    img {
        opacity: 1;
        transition-property: opacity;
        transition-duration: .3s;
        transition-timing-function: ease-in-out;
    }
    &.fetching {
        img {
            opacity: 0;
        }
    }
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -100%; /* Start outside */
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(128, 128, 128, 0), rgba(128, 128, 128, 0));
        animation: moveRight 1s linear infinite;
    }
    &::after {
        background: linear-gradient(to right, rgba(128, 128, 128, 0), rgba(128, 128, 128, 0));
        animation: moveRight 2s linear infinite;
    }
    &.fetching {
        background-color: rgba($color: #003465, $alpha: 0);
        color: rgba($color: #FFFFFF, $alpha: 0);
        &::before {
            background: linear-gradient(to right, rgba(128, 128, 128, 0.5), rgba(128, 128, 128, 0));
        }
        &::after {
            background: linear-gradient(to right, rgba(128, 128, 128, 0.3), rgba(128, 128, 128, 0));
        }
    }

}

@keyframes moveRight {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}